<!--
 * @Description: 首页组件
 * @Author: kong
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: kong
 * @LastEditTime: 2020-02-27 13:36:12
 -->
<template>
  <div class="home" id="home" name="home">
    <!-- 轮播图 -->
    <div class="block" v-show="carouselList.length > 0">
      <div class="dark-mask"></div>
      <div id="header-swiper-container">
        <div class="swiper-wrapper">
          <div
              class="swiper-slide"
              style="cursor: pointer"
              v-for="item in carouselList"
              :key="item.id"
              @click="toLink(item.link)"
          >
            <div class="leftbox">
              <div class="hdader">{{ item.title }}</div>
              <div class="footer">
                <p v-for="items in item.content" :key="items">{{ items }}</p>
              </div>
            </div>
            <div class="rightbox">
              <img
                  :src="item.image"
                  alt=""
              />
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
        <!-- <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div> -->
      </div>
    </div>
    <!-- 轮播图END -->
    <div class="main-box">
      <!--图标内容-->
      <div class="container" v-show="contentList.length > 0">
        <div class="container-item" v-for="item in contentList" :key="item">
          <div class="box-simple">
            <div class="icon">
              <img :src="item.image" alt="" />
            </div>
            <div class="title">
              <div>{{ item.title }}</div>
            </div>
            <div class="description">
              <p>{{ item.content }}</p>
            </div>
          </div>
        </div>
      </div>
      <!--客户评价-->
      <div class="bar" v-show="reviewList.length > 0">
        <div class="heading text-center">
          <span>{{ config.review_title }}</span>
        </div>
        <p class="lead">{{config.review_intro}}</p>
        <div class="owl-carousel">
          <div id="swiper-container">
            <div class="swiper-wrapper">
              <div
                  v-for="item in reviewList"
                  :key="item.id"
                  class="swiper-slide"
              >
                <div class="header">
                  {{ item.content }}
                </div>
                <div class="footer" style="background-color: #fff">
                  <div class="icon">
                    <img src="../assets/imgs/home/fa-quote-left.png" alt="" />
                  </div>

                  <div class="name-picture">
                    <div class="nametext">
                      {{ item.name }}
                      <div class="position">{{ item.position }}</div>
                    </div>

                    <div class="img">
                      <img :src="item.image" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
            <!-- <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div> -->
          </div>
        </div>
      </div>
      <!--See More-->
      <div class="dark-mask">
        <div class="content"></div>
        <div class="icon icon-lg">
          <img :src="config.seemore_img" alt="" />
        </div>
        <div class="text-uppercase">{{config.seemore_title}}</div>
        <div class="lead">{{config.seemore_intro}}</div>
        <div class="btn">
          <el-button class="custom-button">{{config.seemore_btn}}</el-button>
        </div>
      </div>
      <!--Blog-->
      <div class="fromourblog" v-show="blog.length > 0">
        <div class="title">{{config.blog_title}}</div>
        <div class="lead">{{config.blog_intro}}</div>
        <div class="row">
          <div class="box-image-text blog" v-for="item in blog" :key="item">
            <div class="img">
              <img :src="item.image" alt="" />
            </div>
            <div class="content">
              <el-link target="_blank" :underline="false" style="margin: 10px 0px">{{item.position }}</el-link>
              <div class="author-category">
                <span v-if="item.name != ''">BY</span>
                <span v-if="item.name != ''" style="color: #38a7bb;font-weight: bold">
                  &nbsp;&nbsp;{{item.name}}&nbsp;&nbsp;
                </span>
                <span v-if="item.publish_time != ''">&nbsp;&nbsp;ON&nbsp;&nbsp;</span>
                <span v-if="item.publish_time != ''">{{ item.publish_time }}</span>
              </div>
              <div class="intro">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </div>
      <!--客户-->
      <div class="ourclients" v-show="clients.length > 0">
        <div class="heading text-center">
          <span>{{config.clients_title}}</span>
        </div>
        <div class="imgList">
          <div class="imgbox" v-for="item in clients" :key="item.id">
            <a :href="item.link" target="_blank" v-if="item.link != ''">
              <img :src="item.image" alt="" :title="item.title"/>
            </a>
            <img :src="item.image" alt="" v-else :title="item.title"/>
          </div>
        </div>
      </div>
      <!--Footer-->
      <div class="footer">
        <div class="col">
          <div class="leftbox">
            <div class="title">ABOUT US</div>
            <div class="content" v-html="config.about_us"></div>
          </div>
        </div>
        <div class="col">
          <div class="centerbox">
            <div class="title">RECENT POSTS</div>
            <div class="centent">
              <div class="imgandtext">
                <div class="img">
                  <img src="../assets/imgs/home/categoriespost.jpg" alt="" />
                </div>
                <div class="text">CATEGORIES POST</div>
              </div>
              <div style="clear: both; margin-bottom: 15px"></div>
              <div class="imgandtext">
                <div class="img">
                  <img src="../assets/imgs/home/categoriespost.jpg" alt="" />
                </div>
                <div class="text">CATEGORIES POST</div>
              </div>
              <div style="clear: both; margin-bottom: 15px"></div>
              <div class="imgandtext">
                <div class="img">
                  <img src="../assets/imgs/home/categoriespost.jpg" alt="" />
                </div>
                <div class="text">CATEGORIES POST</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="rightbox">
            <div class="title">CONTACT</div>
            <div class="content">
              <div class="text" v-html="config.contact">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
import { getHomeData } from "@/api/home.js";
export default {
  data() {
    return {
      carouselList: [],
      contentList: [],
      reviewList: [],
      blog: [],
      clients: [],
      config:"",
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.getHomeList();
    this.getSwiper();
  },
  methods: {
    getHomeList() {
      getHomeData().then((res) => {
        const { carousel, content, review, blog, clients,config } = res.data;
        this.carouselList = carousel;
        this.contentList = content;
        this.reviewList = review;
        this.blog = blog;
        this.clients = clients;
        this.config = config;
        this.$nextTick(() => {
          this.getHeaderSwiper();
        });
      });
    },
    getSwiper() {
      this.swiper = new Swiper("#swiper-container", {
        loop: true, // 无缝
        // autoplay: {
        //   //自动开始
        //   delay: 3000, //时间间隔
        //   disableOnInteraction: false, //*手动操作轮播图后不会暂停*
        // },
        paginationClickable: true,
        slidesPerView: 4, // 一组三个
        spaceBetween: 15, // 间隔
        // 如果需要前进后退按钮
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
        observer: true,
        observeParents: true,
        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 分页器可以点击
        },
        breakpoints: {
          //当宽度小于等于320
          320: {
            slidesPerView: 1,
           // spaceBetween: 50,
          },
          //当宽度小于等于480
          480: {
            slidesPerView: 2,
          //  spaceBetween: 10,
          },
          //当宽度小于等于640
          640: {
            slidesPerView: 3, // 一组三个
          //  spaceBetween: 10, // 间隔
          },
          //当宽度小于等于992
          //当宽度小于等于1200
          1200: {
            slidesPerView: 4, // 一组三个
            spaceBetween: 15, // 间隔
          },
        },
      });
    },
    getHeaderSwiper() {
      this.heaheaderSwiperderSwiper = new Swiper("#header-swiper-container", {
        loop: true, // 无缝
        // centeredSlides: true, // 居中
        // loopAdditionalSlides: 1, // 循环添加
        autoplay: {
          //自动开始
          delay: 2000, //时间间隔
          disableOnInteraction: false, //*手动操作轮播图后不会暂停*
        },
        paginationClickable: true,
        slidesPerView: 1, // 一组三个
        spaceBetween: 15, // 间隔
        // 如果需要前进后退按钮
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
        observer: true,
        observeParents: true,
        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 分页器可以点击
        },
      });
    },
    toLink(link) {
      if (link !== "") {
        window.open(link, "blank");
      }
    },
  },
};
</script>
<style scoped>
/* @import "../assets/css/index.scss"; */
</style>
<style lang="less" scoped>

</style>
